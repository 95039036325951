.CellPopupWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}

.CellPopupWrapper-background {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  top: 0px;
  left: 0px;
}

.CellPopupWrapper-background:hover {
  cursor: zoom-out;
}

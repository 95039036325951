@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: content-box !important;
}

.clean-link {
  text-decoration: none;
  border-bottom: 0px solid rgba(0, 0, 0, 0.05);
}

.clean-link:focus {
  text-decoration: none;
}

.clean-link:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

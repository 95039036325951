.TX-ScrollBar{
    width:8px;
    background-color: black;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 100px;
    background-color: rgba(193,193,193,0.72);
    transition: background-color 0.5s;
}

.TX-ScrollBar:hover{
    cursor: pointer;
    background-color: rgba(160, 160, 160, 0.979);
}

.TX-ScrollBar-Select{
    cursor: pointer;
    background-color: rgba(160, 160, 160, 0.979);
}
.ges-container {
  position: absolute;
  z-index: 3;
  cursor: default;
}

.ges-contents {
  position: absolute;
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid currentColor;
  box-shadow: 0 0 15px 0px rgba(152, 152, 152, 0.5);
}

.ges-contents:focus {
  box-shadow: 0 0 15px 0px rgba(152, 152, 152, 0.5);
  outline: none;
}

.ges-dimensions {
  max-height: 90vh;
  max-width: 90vw;
  min-height: 30px;
}

.ges-innerContents {
  display: flex;
  overflow: auto;
  border-radius: 8px;
}

.ges-caret {
  position: absolute;
  fill: currentColor;
  height: 24px;
  pointer-events: none;
}

/* @font-face {
    font-family: myriad;
    src: url(myriad-set-pro_extrathin.woff);
} */

.FormBuilderPage {
  /*background-size:cover;*/
  background-color: white;
  font-family: "Roboto";
  font-stretch: normal;
  color: rgba(0, 0, 0, 0.87);
  height: 92.8%;
  width: 100vw;
  min-width: 1100px;
  min-height: 550px;
  overflow: hidden;
}

.FormBuilderPage-HeaderBar {
  width: 100%;
  height: 45px;
  /* min-height: 50px; */
  background-color: rgba(255, 255, 255, 1);
  border-left-width: 0px;
  border-right-width: 0px;
  position: relative;
}

.FormBuilderPage-MainContent {
  width: 100%;
  height: 100%;
  margin: 0px !important;
}

.FormBuilderPage-MainContent-PreviewWindow {
  height: 100%;
  min-height: 550px;
  width: 16%;
  /*background-color: white;*/
  border-right: 1px solid #ecf0f1;
  padding: 0px !important;
  background-color: white;
}

.FormBuilderPage-MainContent-PreviewWindow-AddButton {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px -10px 20px white;
}

.FormBuilderPage-MainContent-ViewWindow {
  height: 100%;
  width: 66%;
  min-height: 550px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.FormBuilderPag-ViewWindow-Container {
  width: 100%;
  height: 100%;
  background-blend-mode: screen;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(166, 166, 166, 0.3) 0px 0px 20px 0px;
}

.FormBuilderPage-MainContent-ViewWindow-Window {
  width: 100%;
  height: calc(100% - 45px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebf0f2;
  padding: 24px;
}

.FormBuilderPage-OnbiardingBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0px;
  z-index: 2;
}

.FormBuilderPage-OnbiardingBackground-Close {
  color: rgba(255, 255, 255, 0.4);
  font-size: 27px;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: color 0.3s ease-in;
}

.FormBuilderPage-OnbiardingBackground-Close:hover {
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.FormBuilderPage-Onboarding-Text {
  color: white;
  font-size: 26px;
  font-family: "Roboto";
}

.FormBuilderPage-Onboarding-QuestionSelector {
  position: absolute;
  bottom: 540px;
  left: 10px;
}

.FormBuilderPage-Onboarding-QuestionEditor-Add {
  position: absolute;
  bottom: 8%;
  left: 10px;
}

.FormBuilderPage-Onboarding-QuestionEditor-Edit {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
}

/* .FormBuilderPage-MainContent-ViewWindow-Window:hover{
  box-shadow: 0px 0px 25px 1px rgba(210,210,210,80);
} */

.FormBuilderPage-MainContent-ToolBox {
  min-height: 550px;
  height: 100%;
  width: 18%;
  padding: 0px !important;
  background-color: white;
  border-left: 1px solid #e1e1e1;
}

.FormBuilderPage-MainContent-ViewWindow-Window-close {
  position: fixed;
  right: 10px;
  top: 2px;
  color: white;
  font-size: 36px;
  opacity: 0.8;
  transition: opacity 0.5s;
  z-index: 10000;
}

.FormBuilderPage-MainContent-ViewWindow-Window-close:hover {
  cursor: pointer;
  opacity: 1;
}

.FormBuilderPage-Onboarding-Share {
  top: 90%;
}

@media only screen and (min-device-width: 768px) {
  .FormBuilderPage-MainContent-ViewWindow-Window-Preview {
    position: fixed;
    height: 95vh !important;
    width: 93vw;
    left: 3.5vw;
    bottom: 2.5vh;
    z-index: 1000;
    border-radius: 7px;
  }

  .FormBuilderPage-MainContent-ViewWindow-Window-Preview-background {
    position: fixed;
    top: 0px;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

@media only screen and (min-device-width: 480px) {
}

@media only screen and (min-width: 768px) {
  .UI_Footer-Icon {
    height: 36px;
    font-size: 18px !important;
  }

  .progress {
    width: 200px !important;
  }

  .UI-Footer-fa-direction {
    display: inline-block !important;
    font-size: 20px !important;
    margin: 0px 6px 0px 6px !important;
  }

  .UI-Footer-SubmitButton-Button {
    width: 250px !important;
  }
}

@media (max-width: 480px) {
  .Questions {
    padding-top: 25vh;
    padding-bottom: 10vh;
    margin: 0 7% 0 7%;
    /* transition: 250ms ease-out; */
  }

  .Question {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 50% 0 50% 0;
    opacity: 0.2;
    transition: opacity 0.2s;
  }

  .Form-Submit {
    position: absolute !important;
    bottom: -900px !important;
    transition: bottom 250ms ease-in-out !important;
    height: 100vh;
  }

  .Form-Submit-Show {
    bottom: 0px !important;
  }

  .Questions-Logo-Link {
    position: absolute;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
  }

  .Questions-divider {
    width: 55%;
    top: 75%;
  }
}
